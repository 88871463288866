import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';

import KuulaProject from './components/KuulaProject';

import './styles/App.css';
import './styles/TabStyles.css';

export default function App() {

	const [ show, setShow ] = useState(false);
	const [ firstLoad, setFirstLoad ] = useState(true);

	function handleClose() {
		setShow(false);
		if ( firstLoad ) {
			setFirstLoad(false);
		}
	}

	function loadingFrame() {
		let el = document.querySelector('#presave-form');
		console.log('e.src', el.src);
	}

	return (
		<div className="App">

			<Modal show={show} onHide={handleClose}>
				<Modal.Body>
					<iframe
						id="presave-form"
						data-lnk-widget
						src="https://wct.live/app/35439/annaofthenorthcrazylife"
						// src="https://AnnaNorth.lnk.to/CrazyLife"
						title="presave" height="700px" width="100%"
						frameborder="0"
						allowtransparency="true"
						onLoad={loadingFrame}>
					</iframe>
				</Modal.Body>
				<Modal.Footer style={{ textAlign: 'center' }}>
					<span onClick={(e) => handleClose(e)} style={{ cursor: 'pointer', color: '#CACACA', fontWeight: 'bold' }}>
						{firstLoad ? 'Skip' : 'Close'}
					</span>
				</Modal.Footer>
			</Modal>

			<KuulaProject />
		
		</div>
	)
}