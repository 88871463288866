import React, { useEffect } from 'react';

export default function KuulaProject(props) {

	useEffect(() => {
		// console.log('window', window)
	}, []);

	return (
		<div>
			<iframe className="ku-embed" 
				title="Anna of the North"
				frameBorder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" 
				src="https://tours.mindpower.dev/share/collection/7vQsB?logo=-1&info=0&fs=0&vr=1&sd=1&thumbs=-1&inst=0&keys=0"></iframe>
    	</div>
	)
}
